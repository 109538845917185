<template>
  <div>
    <div v-if="loadingFormData" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="">{{ $t('CATALOG_LOADING_LABEL', {catalog: $t('DRIVER')})  | capitalize }}</strong>
    </div>
    <b-card bg-variant="dark" text-variant="light" v-else :title="$t('FORMS_GENERAL_DATA_TITLE') | capitalize">
      <b-card-text>
        <pre v-if="debug" class="text-light">
          {{ form }}
        </pre>
        <b-form @submit.prevent="save">
          <!-- photo input -->
          <single-file-upload @change="() => {form.photo = value;}" selection-mode="single"
                              :label="$t('DRIVER_PHOTO_LABEL') | capitalize"
                              max-m-b="50"
                              :current-file="form.photo"
                              :valid-extensions="['jpg','png','bmp']"
                              name="photo"
                              :id="this.form.uploadDirectory"
                              :image="true"></single-file-upload>
          <!-- photo input -->

          <div class="w-100">
            <!-- employeeNumber text input -->
            <b-form-group
                id="employee-number-form-group"
                class="w-50 d-inline-block pr-1"
                :label="$t('DRIVER_EMPLOYEE_NUMBER_LABEL') | capitalize"
                label-for="employeeNumber-input"
                :state="state('employeeNumber')">
              <b-form-input class="w-100" id="employee-number-input" type="text"
                            v-model="$v.form.employeeNumber.$model"
                            :state="state('employeeNumber')"
                            trim></b-form-input>

              <b-form-invalid-feedback id="employee-number-input-feedback">
                <div v-for="error in errors('employeeNumber')" :key="error.error">
                  {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_EMPLOYEE_NUMBER_LABEL')},})  | capitalize }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- employeeNumber text input -->

            <!-- company -->
            <b-form-group
                class="w-50 d-inline-block"
                :label="$t('FORMS_MULTI_SELECT_LABEL', {catalog: $t('COMPANY')}) | capitalize"
                label-for="company-input"
                :state="state('company')">
              <company-single-selector
                  id="company-input"
                  undefined-means="FORMS_SELECT_ONE"
                  :state="state('company')"
                  :errors="errors('company')"
                  v-model="$v.form.company.$model">
              </company-single-selector>
            </b-form-group>
            <!-- company -->
          </div>

          <h3>{{$t('DRIVER_PERSONAL_DATA_TITLE')}}</h3>
          <hr />

          <b-container fluid>
            <b-row>
              <b-col md="4">
                <!-- name text input -->
                <b-form-group
                    id="name-form-group"
                    :label="$t('DRIVER_NAME_LABEL') | capitalize"
                    label-for="name-input"
                    :state="state('name')">
                  <b-form-input class="w-100 " id="name-input" type="text"
                                v-model="$v.form.name.$model"
                                :state="state('name')"
                                trim></b-form-input>

                  <b-form-invalid-feedback id="name-input-feedback">
                    <div v-for="error in errors('name')" :key="error.error">
                      {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_NAME_LABEL')},})  | capitalize }}
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
                <!-- name text input -->

                <!-- fatherLastName text input -->
                <b-form-group
                    id="father-last-name-form-group"
                    :label="$t('DRIVER_FATHER_LAST_NAME_LABEL') | capitalize"
                    label-for="fatherLastName-input"
                    :state="state('fatherLastName')">
                  <b-form-input class="w-100 " id="father-last-name-input" type="text"
                                v-model="$v.form.fatherLastName.$model"
                                :state="state('fatherLastName')"
                                trim></b-form-input>

                  <b-form-invalid-feedback id="father-last-name-input-feedback">
                    <div v-for="error in errors('fatherLastName')" :key="error.error">
                      {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_FATHER_LAST_NAME_LABEL')},})  | capitalize }}
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
                <!-- fatherLastName text input -->

                <!-- motherLastName text input -->
                <b-form-group
                    id="mother-last-name-form-group"
                    :label="$t('DRIVER_MOTHER_LAST_NAME_LABEL') | capitalize"
                    label-for="motherLastName-input"
                    :state="state('motherLastName')">
                  <b-form-input class="w-100 " id="mother-last-name-input" type="text"
                                v-model="$v.form.motherLastName.$model"
                                :state="state('motherLastName')"
                                trim></b-form-input>

                  <b-form-invalid-feedback id="mother-last-name-input-feedback">
                    <div v-for="error in errors('motherLastName')" :key="error.error">
                      {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_MOTHER_LAST_NAME_LABEL')},})  | capitalize }}
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
                <!-- motherLastName text input -->
              </b-col>
              <b-col md="4">
                <!-- dateOfBirth input -->
                <b-form-group
                    id="dateOfBirth-form-group"
                    :label="$t('DRIVER_DATE_OF_BIRTH_LABEL') | capitalize"
                    label-for="dateOfBirth-input"
                    :state="state('dateOfBirth')">
                  <b-input :state="state('dateOfBirth')" v-model="$v.form.dateOfBirth.$model"></b-input>

                  <b-form-invalid-feedback id="dateOfBirth-input-feedback">
                    <div v-for="error in errors('dateOfBirth')" :key="error.error">
                      {{
                        $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_DATE_OF_BIRTH_LABEL')},})  | capitalize
                      }}
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
                <!-- dateOfBirth input -->

                <!-- placeOfBirth text input -->
                <b-form-group
                    id="place-of-birth-form-group"
                    :label="$t('DRIVER_PLACE_OF_BIRTH_LABEL') | capitalize"
                    label-for="placeOfBirth-input"
                    :state="state('placeOfBirth')">
                  <b-form-input class="w-100 " id="place-of-birth-input" type="text"
                                v-model="$v.form.placeOfBirth.$model"
                                :state="state('placeOfBirth')"
                                trim></b-form-input>

                  <b-form-invalid-feedback id="place-of-birth-input-feedback">
                    <div v-for="error in errors('placeOfBirth')" :key="error.error">
                      {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_PLACE_OF_BIRTH_LABEL')},})  | capitalize }}
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
                <!-- placeOfBirth text input -->

                <!-- civilStatus input -->
                <b-form-group
                    id="civilStatus-form-group"
                    :label="$t('DRIVER_CIVIL_STATUS_LABEL') | capitalize"
                    label-for="civilStatus-input"
                    :state="state('civilStatus')">
                  <b-select v-model="$v.form.civilStatus.$model" class="w-100 " id="verification-input">
                    <b-select-option :value="0">{{ $t('DRIVER_CIVIL_STATUS_SINGLE') }}</b-select-option>
                    <b-select-option :value="1">{{ $t('DRIVER_CIVIL_STATUS_MARRIED') }}</b-select-option>
                    <b-select-option :value="2">{{ $t('DRIVER_CIVIL_STATUS_FREE_UNION') }}</b-select-option>
                    <b-select-option :value="3">{{ $t('DRIVER_CIVIL_STATUS_SEPARATED') }}</b-select-option>
                    <b-select-option :value="4">{{ $t('DRIVER_CIVIL_STATUS_DIVORCED') }}</b-select-option>
                    <b-select-option :value="5">{{ $t('DRIVER_CIVIL_STATUS_WIDOW') }}</b-select-option>
                  </b-select>

                  <b-form-invalid-feedback id="civil-status-input-feedback">
                    <div v-for="error in errors('civilStatus')" :key="error.error">
                      {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_CIVIL_STATUS_LABEL')},})  | capitalize }}
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
                <!-- civilStatus input -->
              </b-col>
              <b-col md="4">
                <!-- nationality text input -->
                <b-form-group
                    id="nationality-form-group"
                    :label="$t('DRIVER_NATIONALITY_LABEL') | capitalize"
                    label-for="nationality-input"
                    :state="state('nationality')">
                  <b-form-input class="w-100 " id="nationality-input" type="text"
                                v-model="$v.form.nationality.$model"
                                :state="state('nationality')"
                                trim></b-form-input>

                  <b-form-invalid-feedback id="nationality-input-feedback">
                    <div v-for="error in errors('nationality')" :key="error.error">
                      {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_NATIONALITY_LABEL')},})  | capitalize }}
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
                <!-- nationality text input -->

                <!-- sex input -->
                <b-form-group
                    id="sex-form-group"
                    :label="$t('DRIVER_SEX_LABEL') | capitalize"
                    label-for="sex-input"
                    :state="state('sex')">
                  <b-select v-model="$v.form.sex.$model" class="w-100 " id="verification-input">
                    <b-select-option :value="0">{{ $t('DRIVER_SEX_MALE') }}</b-select-option>
                    <b-select-option :value="1">{{ $t('DRIVER_SEX_FEMALE') }}</b-select-option>
                  </b-select>

                  <b-form-invalid-feedback id="sex-input-feedback">
                    <div v-for="error in errors('sex')" :key="error.error">
                      {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_SEX_LABEL')},})  | capitalize }}
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
                <!-- sex input -->

                <!-- phone text input -->
                <b-form-group
                    id="phone-form-group"
                    :label="$t('DRIVER_PHONE_LABEL') | capitalize"
                    label-for="phone-input"
                    :state="state('phone')">
                  <b-form-input class="w-100 " id="phone-input" type="text"
                                v-model="$v.form.phone.$model"
                                :state="state('phone')"
                                trim></b-form-input>

                  <b-form-invalid-feedback id="phone-input-feedback">
                    <div v-for="error in errors('phone')" :key="error.error">
                      {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_PHONE_LABEL')},})  | capitalize }}
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
                <!-- phone text input -->
              </b-col>
            </b-row>
          </b-container>

          <h3>{{$t('DRIVER_ADDRESS_DATA_TITLE')}}</h3>
          <hr />

          <div class="w-100">
            <!-- address text input -->
            <b-form-group
                id="address-form-group"
                class="d-inline-block w-33 pr-1"
                :label="$t('DRIVER_ADDRESS_LABEL') | capitalize"
                label-for="address-input"
                :state="state('address')">
              <b-form-input class="w-100 " id="address-input" type="text"
                            v-model="$v.form.address.$model"
                            :state="state('address')"
                            trim></b-form-input>

              <b-form-invalid-feedback id="address-input-feedback">
                <div v-for="error in errors('address')" :key="error.error">
                  {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_ADDRESS_LABEL')},})  | capitalize }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- address text input -->

            <!-- street2 text input -->
            <b-form-group
                id="street2-form-group"
                class="d-inline-block w-33 pr-1"
                :label="$t('DRIVER_STREET2_LABEL') | capitalize"
                label-for="street2-input"
                :state="state('street2')">
              <b-form-input class="w-100 " id="street2-input" type="text"
                            v-model="$v.form.street2.$model"
                            :state="state('street2')"
                            trim></b-form-input>

              <b-form-invalid-feedback id="street2-input-feedback">
                <div v-for="error in errors('street2')" :key="error.error">
                  {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_STREET2_LABEL')},})  | capitalize }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- street2 text input -->

            <!-- zipCode text input -->
            <b-form-group
                id="zip-code-form-group"
                class="d-inline-block w-33"
                :label="$t('DRIVER_ZIP_CODE_LABEL') | capitalize"
                label-for="zipCode-input"
                :state="state('zipCode')">
              <b-form-input class="w-100 " id="zip-code-input" type="text"
                            v-model="$v.form.zipCode.$model"
                            :state="state('zipCode')"
                            trim></b-form-input>

              <b-form-invalid-feedback id="zip-code-input-feedback">
                <div v-for="error in errors('zipCode')" :key="error.error">
                  {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_ZIP_CODE_LABEL')},})  | capitalize }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- zipCode text input -->
          </div>

          <h3>{{$t('DRIVER_DOCUMENTATION_DATA_TITLE')}}</h3>
          <hr />

          <div class="w-100">
            <!-- curp text input -->
            <b-form-group
                id="curp-form-group"
                class="d-inline-block w-25 pr-1"
                :label="$t('DRIVER_CURP_LABEL') | capitalize"
                label-for="curp-input"
                :state="state('curp')">
              <b-form-input class="w-100 " id="curp-input" type="text"
                            v-model="$v.form.curp.$model"
                            :state="state('curp')"
                            trim></b-form-input>

              <b-form-invalid-feedback id="curp-input-feedback">
                <div v-for="error in errors('curp')" :key="error.error">
                  {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_CURP_LABEL')},})  | capitalize }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- curp text input -->

            <!-- license text input -->
            <b-form-group
                id="license-form-group"
                class="d-inline-block  w-25 pr-1"
                :label="$t('DRIVER_LICENSE_LABEL') | capitalize"
                label-for="license-input"
                :state="state('license')">
              <b-input class="w-100 " id="license-input"
                            v-model="$v.form.license.$model"
                            :state="state('license')">
              </b-input>

              <b-form-invalid-feedback id="license-input-feedback">
                <div v-for="error in errors('license')" :key="error.error">
                  {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_LICENSE_LABEL')},})  | capitalize }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- license text input -->

            <!-- licensePhoto input -->
            <single-file-upload @change="() => {form.licensePhoto = value;}" selection-mode="single"
                                :label="$t('DRIVER_LICENSE_PHOTO_LABEL') | capitalize"
                                max-m-b="50"
                                :current-file="form.licensePhoto"
                                :valid-extensions="['jpg','png','bmp']"
                                name="licensePhoto"
                                :id="this.form.uploadDirectory"
                                :image="true"></single-file-upload>
            <!-- licensePhoto input -->

            <!-- idNumber text input -->
            <b-form-group
                id="id-number-form-group"
                class="d-inline-block w-25 pr-1"
                :label="$t('DRIVER_ID_NUMBER_LABEL') | capitalize"
                label-for="idNumber-input"
                :state="state('idNumber')">
              <b-form-input class="w-100 " id="id-number-input" type="text"
                            v-model="$v.form.idNumber.$model"
                            :state="state('idNumber')"
                            trim></b-form-input>

              <b-form-invalid-feedback id="id-number-input-feedback">
                <div v-for="error in errors('idNumber')" :key="error.error">
                  {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_ID_NUMBER_LABEL')},})  | capitalize }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- idNumber text input -->

            <!-- driverType input -->
            <b-form-group
                id="driverType-form-group"
                class="d-inline-block w-25"
                :label="$t('DRIVER_DRIVER_TYPE_LABEL') | capitalize"
                label-for="driverType-input"
                :state="state('driverType')">
              <b-select v-model="$v.form.driverType.$model" class="w-100 " id="verification-input">
                <b-select-option :value="0">{{ $t('DRIVER_TYPE_EMPLOYEE') }}</b-select-option>
                <b-select-option :value="1">{{ $t('DRIVER_TYPE_ASSOCIATED') }}</b-select-option>
              </b-select>

              <b-form-invalid-feedback id="driver-type-input-feedback">
                <div v-for="error in errors('driverType')" :key="error.error">
                  {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_DRIVER_TYPE_LABEL')},})  | capitalize }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- driverType input -->
          </div>

          <div class="100">
            <!-- rfc text input -->
            <b-form-group
                id="rfc-form-group"
                class="d-inline-block w-25 pr-1"
                :label="$t('DRIVER_RFC_LABEL') | capitalize"
                label-for="rfc-input"
                :state="state('rfc')">
              <b-form-input class="w-100 " id="rfc-input" type="text"
                            v-model="$v.form.rfc.$model"
                            :state="state('rfc')"
                            trim></b-form-input>

              <b-form-invalid-feedback id="rfc-input-feedback">
                <div v-for="error in errors('rfc')" :key="error.error">
                  {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_RFC_LABEL')},})  | capitalize }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- rfc text input -->

            <!-- expeditionDate input -->
            <b-form-group
                id="expeditionDate-form-group"
                class="d-inline-block w-25 pr-1"
                :label="$t('DRIVER_EXPEDITION_DATE_LABEL') | capitalize"
                label-for="expeditionDate-input"
                :state="state('expeditionDate')">
              <b-input :state="state('expeditionDate')" v-model="$v.form.expeditionDate.$model"></b-input>

              <b-form-invalid-feedback id="expeditionDate-input-feedback">
                <div v-for="error in errors('expeditionDate')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_EXPEDITION_DATE_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- expeditionDate input -->

            <!-- nss text input -->
            <b-form-group
                id="nss-form-group"
                class="d-inline-block w-25 pr-1"
                :label="$t('DRIVER_NSS_LABEL') | capitalize"
                label-for="nss-input"
                :state="state('nss')">
              <b-form-input class="w-100 " id="nss-input" type="text"
                            v-model="$v.form.nss.$model"
                            :state="state('nss')"
                            trim></b-form-input>

              <b-form-invalid-feedback id="nss-input-feedback">
                <div v-for="error in errors('nss')" :key="error.error">
                  {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_NSS_LABEL')},})  | capitalize }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- nss text input -->

            <!-- paymentType input -->
            <b-form-group
                id="paymentType-form-group"
                class="d-inline-block w-25"
                :label="$t('DRIVER_PAYMENT_TYPE_LABEL') | capitalize"
                label-for="paymentType-input"
                :state="state('paymentType')">
              <b-select v-model="$v.form.paymentType.$model" class="w-100 " id="verification-input">
                <b-select-option :value=" 0">{{ $t('DRIVER_PAYMENT_PAYROLL') }}</b-select-option>
                <b-select-option :value="1">{{ $t('DRIVER_PAYMENT_CASH') }}</b-select-option>
              </b-select>

              <b-form-invalid-feedback id="payment-type-input-feedback">
                <div v-for="error in errors('paymentType')" :key="error.error">
                  {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_PAYMENT_TYPE_LABEL')},})  | capitalize }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- paymentType input -->
          </div>

          <div class="w-100">
            <div class="d-inline-block w-25"></div>
            <!-- dueDate input -->
            <b-form-group
                id="dueDate-form-group"
                class="d-inline-block w-25 pr-1"
                :label="$t('DRIVER_DUE_DATE_LABEL') | capitalize"
                label-for="dueDate-input"
                :state="state('dueDate')">
              <b-input :state="state('dueDate')" v-model="$v.form.dueDate.$model"></b-input>

              <b-form-invalid-feedback id="dueDate-input-feedback">
                <div v-for="error in errors('dueDate')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_DUE_DATE_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- dueDate input -->

            <!-- position text input -->
            <b-form-group
                id="position-form-group"
                class="d-inline-block w-25"
                :label="$t('DRIVER_POSITION_LABEL') | capitalize"
                label-for="position-input"
                :state="state('position')">
              <b-form-input class="w-100 " id="position-input" type="text"
                            v-model="$v.form.position.$model"
                            :state="state('position')"
                            trim></b-form-input>

              <b-form-invalid-feedback id="position-input-feedback">
                <div v-for="error in errors('position')" :key="error.error">
                  {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_POSITION_LABEL')},})  | capitalize }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- position text input -->
            <div class="d-inline-block w-25"></div>
          </div>

          <div class="w-100">
            <!-- document1 input -->
            <b-form-group
                id="document1-form-group"
                class="w-33 d-inline-block pr-1"
                :label="$t('DRIVER_DOCUMENT1_LABEL') | capitalize"
                label-for="document1-input"
                :state="state('document1')">
              <b-checkbox v-model="$v.form.document1.$model" class="w-100 " id="document1-input"></b-checkbox>

              <b-form-invalid-feedback id="document1-input-feedback">
                <div v-for="error in errors('document1')" :key="error.error">
                  {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_DOCUMENT1_LABEL')},})  | capitalize }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- document1 input -->

            <!-- document2 input -->
            <b-form-group
                id="document2-form-group"
                class="w-33 d-inline-block pr-1"
                :label="$t('DRIVER_DOCUMENT2_LABEL') | capitalize"
                label-for="document2-input"
                :state="state('document2')">
              <b-checkbox v-model="$v.form.document2.$model" class="w-100 " id="document2-input"></b-checkbox>

              <b-form-invalid-feedback id="document2-input-feedback">
                <div v-for="error in errors('document2')" :key="error.error">
                  {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_DOCUMENT2_LABEL')},})  | capitalize }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- document2 input -->

            <!-- document3 input -->
            <b-form-group
                id="document3-form-group"
                class="w-33 d-inline-block"
                :label="$t('DRIVER_DOCUMENT3_LABEL') | capitalize"
                label-for="document3-input"
                :state="state('document3')">
              <b-checkbox v-model="$v.form.document3.$model" class="w-100 " id="document3-input"></b-checkbox>

              <b-form-invalid-feedback id="document3-input-feedback">
                <div v-for="error in errors('document3')" :key="error.error">
                  {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_DOCUMENT3_LABEL')},})  | capitalize }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- document3 input -->
          </div>

          <h3>{{$t('DRIVER_DOCUMENT_REGISTRY_TITLE')}}</h3>
          <hr />

          <div class="w-100">
            <!-- document4 input -->
            <b-form-group
                id="document4-form-group"
                class="w-33 d-inline-block pr-1"
                :label="$t('DRIVER_DOCUMENT4_LABEL') | capitalize"
                label-for="document4-input"
                :state="state('document4')">
              <b-checkbox v-model="$v.form.document4.$model" class="w-100 " id="document4-input"></b-checkbox>

              <b-form-invalid-feedback id="document4-input-feedback">
                <div v-for="error in errors('document4')" :key="error.error">
                  {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_DOCUMENT4_LABEL')},})  | capitalize }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- document4 input -->

            <!-- document5 input -->
            <b-form-group
                id="document5-form-group"
                class="w-33 d-inline-block pr-1"
                :label="$t('DRIVER_DOCUMENT5_LABEL') | capitalize"
                label-for="document5-input"
                :state="state('document5')">
              <b-checkbox v-model="$v.form.document5.$model" class="w-100 " id="document5-input"></b-checkbox>

              <b-form-invalid-feedback id="document5-input-feedback">
                <div v-for="error in errors('document5')" :key="error.error">
                  {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_DOCUMENT5_LABEL')},})  | capitalize }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- document5 input -->

            <!-- document6 input -->
            <b-form-group
                id="document6-form-group"
                class="w-33 d-inline-block"
                :label="$t('DRIVER_DOCUMENT6_LABEL') | capitalize"
                label-for="document6-input"
                :state="state('document6')">
              <b-checkbox v-model="$v.form.document6.$model" class="w-100 " id="document6-input"></b-checkbox>

              <b-form-invalid-feedback id="document6-input-feedback">
                <div v-for="error in errors('document6')" :key="error.error">
                  {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_DOCUMENT6_LABEL')},})  | capitalize }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- document6 input -->
          </div>

          <div class="w-100">
            <!-- document7 input -->
            <b-form-group
                id="document7-form-group"
                class="w-33 d-inline-block pr-1"
                :label="$t('DRIVER_DOCUMENT7_LABEL') | capitalize"
                label-for="document7-input"
                :state="state('document7')">
              <b-checkbox v-model="$v.form.document7.$model" class="w-100 " id="document7-input"></b-checkbox>

              <b-form-invalid-feedback id="document7-input-feedback">
                <div v-for="error in errors('document7')" :key="error.error">
                  {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_DOCUMENT7_LABEL')},})  | capitalize }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- document7 input -->

            <!-- document8 input -->
            <b-form-group
                id="document8-form-group"
                class="w-33 d-inline-block pr-1"
                :label="$t('DRIVER_DOCUMENT8_LABEL') | capitalize"
                label-for="document8-input"
                :state="state('document8')">
              <b-checkbox v-model="$v.form.document8.$model" class="w-100 " id="document8-input"></b-checkbox>

              <b-form-invalid-feedback id="document8-input-feedback">
                <div v-for="error in errors('document8')" :key="error.error">
                  {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_DOCUMENT8_LABEL')},})  | capitalize }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- document8 input -->

            <!-- document9 input -->
            <b-form-group
                id="document9-form-group"
                class="w-33 d-inline-block"
                :label="$t('DRIVER_DOCUMENT9_LABEL') | capitalize"
                label-for="document9-input"
                :state="state('document9')">
              <b-checkbox v-model="$v.form.document9.$model" class="w-100 " id="document9-input"></b-checkbox>

              <b-form-invalid-feedback id="document9-input-feedback">
                <div v-for="error in errors('document9')" :key="error.error">
                  {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_DOCUMENT9_LABEL')},})  | capitalize }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- document9 input -->
          </div>

          <!-- document10 input -->
          <b-form-group
              id="document10-form-group"
              :label="$t('DRIVER_DOCUMENT10_LABEL') | capitalize"
              label-for="document10-input"
              :state="state('document10')">
            <b-checkbox v-model="$v.form.document10.$model" class="w-100 " id="document10-input"></b-checkbox>

            <b-form-invalid-feedback id="document10-input-feedback">
              <div v-for="error in errors('document10')" :key="error.error">
                {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_DOCUMENT10_LABEL')},})  | capitalize }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- document10 input -->

          <h3>{{$t('DRIVER_HEALTH_DATA_TITLE')}}</h3>
          <hr />

          <!-- cronicDesease input -->
          <b-form-group
              id="cronicDesease-form-group"
              :label="$t('DRIVER_CRONIC_DESEASE_LABEL') | capitalize"
              label-for="cronicDesease-input"
              :state="state('cronicDesease')">
            <b-checkbox v-model="$v.form.cronicDesease.$model" class="w-100 " id="cronicDesease-input"></b-checkbox>

            <b-form-invalid-feedback id="cronic-desease-input-feedback">
              <div v-for="error in errors('cronicDesease')" :key="error.error">
                {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_CRONIC_DESEASE_LABEL')},})  | capitalize }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- cronicDesease input -->

          <h3>{{$t('DRIVER_REGISTRY_TITLE')}}</h3>
          <hr />

          <div class="w-100">
            <!-- inDate input -->
            <b-form-group
                id="inDate-form-group"
                class="w-50 d-inline-block pr-1"
                :label="$t('DRIVER_IN_DATE_LABEL') | capitalize"
                label-for="inDate-input"
                :state="state('inDate')">
              <b-input :state="state('inDate')" v-model="$v.form.inDate.$model"></b-input>

              <b-form-invalid-feedback id="inDate-input-feedback">
                <div v-for="error in errors('inDate')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_IN_DATE_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- inDate input -->

            <!-- outDate input -->
            <b-form-group
                id="outDate-form-group"
                class="w-50 d-inline-block pr-1"
                :label="$t('DRIVER_OUT_DATE_LABEL') | capitalize"
                label-for="outDate-input"
                :state="state('outDate')">
              <b-input :state="state('outDate')" v-model="$v.form.outDate.$model"></b-input>

              <b-form-invalid-feedback id="outDate-input-feedback">
                <div v-for="error in errors('outDate')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DRIVER_OUT_DATE_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- outDate input -->
          </div>

          <b-button class="mt-2" type="submit" :disabled="saving" variant="primary">
            <b-icon v-if="saving" icon="circle-fill" animation="throb" class="mr-2"></b-icon>
            <span v-if="saving">{{ $t('FORMS_SAVING_LABEL')  | capitalize }}</span>
            <span v-else>{{ $t('FORMS_SAVE_LABEL')  | capitalize }}</span>
          </b-button>
        </b-form>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {minLength, maxLength, required, helpers} from "vuelidate/lib/validators";
import {Form, ProcessWithLoadingAndMessage} from "@/mixins";
import Driver from "@/drivers";
import Constants from "@/constants";
import SingleFileUpload from "@/components/SingleFileUpload";

const regex = helpers.regex('regex', /^([A-ZÑ&]{3,4}) ?(?:- ?)?(d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]d|3[01])) ?(?:- ?)?([A-Zd]{2})([Ad])$/);
import CompanySingleSelector from "@/companies/CompanySingleSelector"
import {v4} from "uuid";
import moment from "moment";

export default {
  name: "DriverForm",
  components: {SingleFileUpload, CompanySingleSelector},
  mixins: [Form, ProcessWithLoadingAndMessage],
  methods: {
    async doSave(id, data) {
      return id ? await Driver.update(id, data) : await Driver.create(data);
    },
    beforeSave(form) {
      form.$touch();
      if (form.$invalid) {
        if (Constants.DEBUG) {
          console.log(form);
        }
        throw new Error('Form is invalid');
      }
      form.dateOfBirth.$model = moment(form.dateOfBirth.$model, "DD/MM/YYYY").toDate();
      form.expeditionDate.$model = moment(form.expeditionDate.$model, "DD/MM/YYYY").toDate();
      form.dueDate.$model = moment(form.dueDate.$model, "DD/MM/YYYY").toDate();
      form.inDate.$model = moment(form.inDate.$model, "DD/MM/YYYY").toDate();
      form.outDate.$model = moment(form.outDate.$model, "DD/MM/YYYY").toDate();
    },
    getData() {
      this.loadingFormData = true;
      Driver.findById(this.id)
          .then(resp => {
            this.form.uploadDirectory = resp.data.uploadDirectory;
            this.form.photo = resp.data.photo;
            this.form.employeeNumber = resp.data.employeeNumber;
            this.form.name = resp.data.name;
            this.form.dateOfBirth = resp.data.dateOfBirth ? moment(resp.data.dateOfBirth).format("DD/MM/yyyy") : undefined;
            this.form.nationality = resp.data.nationality;
            this.form.fatherLastName = resp.data.fatherLastName;
            this.form.placeOfBirth = resp.data.placeOfBirth;
            this.form.sex = resp.data.sex;
            this.form.motherLastName = resp.data.motherLastName;
            this.form.civilStatus = resp.data.civilStatus;
            this.form.phone = resp.data.phone;
            this.form.address = resp.data.address;
            this.form.street2 = resp.data.street2;
            this.form.zipCode = resp.data.zipCode;
            this.form.curp = resp.data.curp;
            this.form.license = resp.data.license;
            this.form.licensePhoto = resp.data.licensePhoto;
            this.form.idNumber = resp.data.idNumber;
            this.form.driverType = resp.data.driverType;
            this.form.rfc = resp.data.rfc;
            this.form.expeditionDate = resp.data.expeditionDate ? moment(resp.data.expeditionDate).format("DD/MM/yyyy") : undefined;
            this.form.nss = resp.data.nss;
            this.form.paymentType = resp.data.paymentType;
            this.form.dueDate = resp.data.dueDate ? moment(resp.data.dueDate).format("DD/MM/yyyy") : undefined;
            this.form.position = resp.data.position;
            this.form.document1 = resp.data.document1;
            this.form.document2 = resp.data.document2;
            this.form.document3 = resp.data.document3;
            this.form.document4 = resp.data.document4;
            this.form.document5 = resp.data.document5;
            this.form.document6 = resp.data.document6;
            this.form.document7 = resp.data.document7;
            this.form.document8 = resp.data.document8;
            this.form.document9 = resp.data.document9;
            this.form.document10 = resp.data.document10;
            this.form.cronicDesease = resp.data.cronicDesease;
            this.form.inDate = resp.data.inDate ? moment(resp.data.inDate).format("DD/MM/yyyy") : undefined;
            this.form.outDate = resp.data.outDate ? moment(resp.data.outDate).format("DD/MM/yyyy") : undefined;
            this.form.company = resp.data._embedded.company ? resp.data._embedded.company._links.self.href.replace('{?projection}', '') : undefined;
            this.originals.nationality = resp.data.nationality
          })
          .catch(e => {
            if (Constants.DEBUG) {
              console.error(e);
            }
            this.sendError('%loadingFormDataErrorMessage%', e);
          })
          .finally(() => {
            this.loadingFormData = false;
          });
    }
  },
  data() {
    return {
      min: new Date(1950, 1, 1),
      loadingFormData: false,
      originals: {
        nationality: null
      },
      form: {
        uploadDirectory: v4(),
        photo: null,
        employeeNumber: null,
        name: null,
        dateOfBirth: null,
        nationality: null,
        fatherLastName: null,
        placeOfBirth: null,
        sex: null,
        motherLastName: null,
        civilStatus: null,
        phone: null,
        address: null,
        street2: null,
        zipCode: null,
        curp: null,
        license: null,
        licensePhoto: null,
        idNumber: null,
        driverType: null,
        rfc: null,
        expeditionDate: null,
        nss: null,
        paymentType: null,
        dueDate: null,
        position: null,
        document1: null,
        document2: null,
        document3: null,
        document4: null,
        document5: null,
        document6: null,
        document7: null,
        document8: null,
        document9: null,
        document10: null,
        cronicDesease: null,
        inDate: moment().format("DD/MM/yyyy"),
        outDate: null,
        company: undefined
      },
      editPage: 'DRIVER_EDIT_TITLE'
    }
  },
  validations: {
    form: {
      photo: {},
      employeeNumber: {minLength: minLength(1), maxLength: maxLength(100)},
      name: {required, minLength: minLength(1), maxLength: maxLength(100)},
      dateOfBirth: {required, validDate: (value) => {
        const m = moment(value, 'DD/MM/YYYY', true);
        return m.isValid();
        }},
      nationality: {minLength: minLength(1), maxLength: maxLength(100)},
      fatherLastName: {required, minLength: minLength(1), maxLength: maxLength(100)},
      placeOfBirth: {minLength: minLength(1), maxLength: maxLength(100)},
      sex: {required},
      motherLastName: {minLength: minLength(1), maxLength: maxLength(100)},
      civilStatus: {required},
      phone: {minLength: minLength(1), maxLength: maxLength(100)},
      address: {minLength: minLength(1), maxLength: maxLength(100)},
      street2: {minLength: minLength(1), maxLength: maxLength(100)},
      zipCode: {minLength: minLength(1), maxLength: maxLength(100)},
      curp: {minLength: minLength(1), maxLength: maxLength(100)},
      license: {minLength: minLength(1), maxLength: maxLength(100)},
      licensePhoto: {},
      idNumber: {minLength: minLength(1), maxLength: maxLength(100)},
      driverType: {},
      rfc: {minLength: minLength(12), pattern: regex(), maxLength: maxLength(13)},
      expeditionDate: {validDate: (value) => {
          const m = moment(value, 'DD/MM/YYYY', true);
          return value === '' || !value || m.isValid();
        }},
      nss: {minLength: minLength(1), maxLength: maxLength(100)},
      paymentType: {},
      dueDate: {validDate: (value) => {
          const m = moment(value, 'DD/MM/YYYY', true);
          return value === '' || !value || m.isValid();
        }},
      position: {minLength: minLength(1), maxLength: maxLength(100)},
      document1: {},
      document2: {},
      document3: {},
      document4: {},
      document5: {},
      document6: {},
      document7: {},
      document8: {},
      document9: {},
      document10: {},
      cronicDesease: {},
      inDate: {validDate: (value) => {
          const m = moment(value, 'DD/MM/YYYY', true);
          return value === '' || !value || m.isValid();
        }},
      outDate: {validDate: (value) => {
          const m = moment(value, 'DD/MM/YYYY', true);
          return value === '' || !value || m.isValid();
        }},
      company: {}
    }
  }
}
</script>

<style scoped>

</style>
